import React from 'react';

import { Checkbox, FlexLayout, Text } from '~/ui';
import { displayNumber } from '~/utils/strings';
import { creditRatings } from '~/components/Shared/CreditRatingSingleSelect';

function getParticipantData(participant, userInfo, isFixed = true) {
  const { id, name, country, industry, creditRating, creditInterestRate, debitInterestRate, currency } = participant;
  const unit = isFixed ? '%' : ' bps';

  return {
    id,
    country,
    industry,
    name,
    rating: creditRating.rating,
    ratingAdj: creditRating.ratingAdj,
    cirString:
      creditInterestRate != null ? `${displayNumber(creditInterestRate, userInfo.decimalPoint, '-')}${unit}` : '-',
    dirString:
      debitInterestRate != null ? `${displayNumber(debitInterestRate, userInfo.decimalPoint, '-')}${unit}` : '-',
    currency,
  };
}

export function getParticipantsData(data = [], userInfo) {
  return data.map((item) => getParticipantData(item, userInfo));
}

export function getParticipantsColumns(isNotional) {
  const columns = [
    {
      label: 'Company',
      sortBy: 'name',
      value: 'name',
      width: 250,
      renderCustomCell: ({ name }) => (
        <FlexLayout disabled={true} alignItems="center" space={2}>
          <Checkbox isActive={true} />
          <Text color="deep-sapphire" variant="m-spaced">
            {name}
          </Text>
        </FlexLayout>
      ),
    },
    { label: 'Country', sortBy: 'country', value: 'country' },
    { label: 'Rating', sortArray: creditRatings, sortBy: 'rating', sortType: 'array', value: 'rating' },
    { label: 'Sector', sortBy: 'industry', value: 'industry' },
    { label: 'Rating (ADJ.)', sortBy: 'ratingAdj', value: 'ratingAdj' },
    { label: 'CREDIT RATE', sortBy: 'cirString', value: 'cirString' },
    { label: 'DEBIT RATE', sortBy: 'dirString', value: 'dirString' },
  ];

  if (isNotional) columns.push({ label: 'CURRENCY', sortBy: 'currency', value: 'currency' });
  return columns;
}
