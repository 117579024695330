import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  Lender: true,
  Borrower: true,
  Currency: true,
  Amount: true,
  'Issue date': true,
  'Maturity date': true,
  'Tenor (yr.)': false,
  'Principal repayment': false,
  'Rate type': false,
  'Day count': false,
  'Interest compounding frequency': false,
  Seniority: false,
  'Borrower rating standalone': false,
  'Borrower rating implicit adj.': false,
  Created: false,
  Updated: false,
  Status: true,
  'Loan type': false,
  'Lower bound': false,
  Base: false,
  'Upper bound': false,
  Deleted: false,
  'Lender Type': false,
};

export const loanColumnsSlice = createSlice({
  name: 'loanColumns',
  initialState: initialState,
  reducers: {
    resetForm: () => {
      return initialState;
    },
    setColumns: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateColumns: (state, action) => {
      return action.payload;
    },
  },
});

export default loanColumnsSlice.reducer;

export const { resetForm, setColumns, updateColumns } = loanColumnsSlice.actions;

export const loanColumns = (state) => state.loanColumns;
