import 'react-datepicker/dist/react-datepicker.css';
import '~/styles/react-datepicker-overrides.css';

import format from 'date-fns/format';
import PT from 'prop-types';
import { useContext } from 'react';
import DatePicker from 'react-datepicker';

import { UserInfoContext } from '~/context/user';
import { DATE_FNS_FORMATS } from '~/enums/dates';
import { Box } from '~/ui';
import { withLabel } from '~/ui/hocs';

import { CustomDateInput, renderCustomHeader, setDate } from './DateInput.utils';

const widths = {
  s: '114px',
  m: '252px',
  l: '536px',
  fullWidth: '100%',
};

function DateInput({
  error,
  maxDate,
  minDate,
  placeholder = '',
  dataTestId,
  sx = {},
  disabled,
  value,
  width = 'm',
  onChange,
}) {
  const { userInfo } = useContext(UserInfoContext);
  const disabledStyle = disabled ? { opacity: 0.7 } : {};

  return (
    <Box
      sx={{
        flexShrink: '0',
        ...sx,
        ...disabledStyle,
        border: error ? 'border-error' : null,
        borderRadius: error ? 'm' : null,
      }}
      dataTestId={dataTestId}
    >
      <DatePicker
        customInput={<CustomDateInput placeholder={placeholder} width={widths[width]} />}
        renderCustomHeader={renderCustomHeader}
        dropdownMode="select"
        maxDate={setDate(maxDate)}
        minDate={setDate(minDate)}
        selected={setDate(value)}
        showMonthDropdown={true}
        showYearDropdown={true}
        popperPlacement="bottom-center"
        disabled={disabled}
        onChange={(updatedDate) => onChange(format(new Date(updatedDate), DATE_FNS_FORMATS.ISO))}
        dateFormat={DATE_FNS_FORMATS[userInfo.dateFormat]}
      />
    </Box>
  );
}

DateInput.propTypes = {
  error: PT.string,
  label: PT.string,
  placeholder: PT.string,
  sx: PT.object,
  tooltip: PT.string,
  value: PT.oneOfType([PT.string, PT.object]),
  width: PT.oneOf(Object.keys(widths)),
  onChange: PT.func,
};

export default withLabel(DateInput);
