import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { reportEnum } from '~/enums';
import {
  guaranteeColumns,
  updateColumns as updateGuaranteeColumns,
} from '~/reducers/tableColumns/guaranteeColumns.slice';
import { loanColumns, updateColumns as updateLoanColumns } from '~/reducers/tableColumns/loanColumns.slice';
import {
  backToBackLoanColumns,
  updateColumns as updateBackToBackLoanColumns,
} from '~/reducers/tableColumns/backToBackLoanColumns.slice';
import { Button, FlexLayout, Modal } from '~/ui';

const typeToActionMapper = {
  [reportEnum.LOAN]: {
    reportColumns: loanColumns,
    updateColumns: updateLoanColumns,
  },
  [reportEnum.BACK_TO_BACK_LOAN]: {
    reportColumns: backToBackLoanColumns,
    updateColumns: updateBackToBackLoanColumns,
  },
  [reportEnum.GUARANTEE]: {
    reportColumns: guaranteeColumns,
    updateColumns: updateGuaranteeColumns,
  },
};

function FilterColumnsModal({ type, handleOnHide }) {
  const dispatch = useDispatch();
  const columns = useSelector(typeToActionMapper[type].reportColumns);
  const [columnsCopy, setColumnsCopy] = useState({ ...columns });

  function handleOnSelectClick() {
    const updateColumns = typeToActionMapper[type].updateColumns;
    dispatch(updateColumns(columnsCopy));
    handleOnHide();
  }

  return (
    <Modal
      actionButtons={<Button text="Select" onClick={handleOnSelectClick} />}
      title="Add or remove columns."
      width="s"
      onHide={handleOnHide}
    >
      <FlexLayout alignItems="center" flexWrap="wrap" justifyContent="flex-start" space={4} sx={{ mt: -4 }}>
        {Object.keys(columnsCopy).map((column) => (
          <Button
            key={column}
            size="s"
            sx={{ mt: 4 }}
            text={column}
            variant={columnsCopy[column] ? 'primary' : 'gray'}
            onClick={() =>
              setColumnsCopy((_state) => {
                return { ..._state, [column]: !_state[column] };
              })
            }
          />
        ))}
      </FlexLayout>
    </Modal>
  );
}

export default FilterColumnsModal;
