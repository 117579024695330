import { BrowserRouter } from 'react-router-dom';

import RedirectAfterLogin from 'components/App/redirectAfterLogin';
import MainLayout from 'components/MainLayout';
import UnhandledNotificationsContextProvider from 'context/notification';
import UserInfoContextProvider from 'context/user';
import CountryContextProvider from 'context/country';
import { SentryConfig } from 'services';

import Helmet from './Helmet';
import MaintenanceModal from './MaintenanceModal';

function App() {
  const isMaintenance = false;

  if (isMaintenance) return <MaintenanceModal />;

  return (
    <BrowserRouter>
      <Helmet />
      <UserInfoContextProvider>
        <UnhandledNotificationsContextProvider>
          <CountryContextProvider>
            <RedirectAfterLogin>
              <SentryConfig />
              <MainLayout />
            </RedirectAfterLogin>
          </CountryContextProvider>
        </UnhandledNotificationsContextProvider>
      </UserInfoContextProvider>
    </BrowserRouter>
  );
}

export default App;
