import { DateTime } from '~/components/Shared';
import { creditRatings } from '~/components/Shared/CreditRatingSingleSelect';
import { Text } from '~/ui';
import { formatDateString } from '~/utils/dates';
import { displayNumber } from '~/utils/strings';
import { getVisibleColumns } from '~/utils/tables';

export function getCreditRatingsData(data = [], userInfo) {
  return data.map((item) => {
    const {
      company,
      isTemplateUploaded,
      closingDate,
      creditRating,
      createdAt,
      updatedAt,
      deletedAt,
      editable,
      id,
      isPortfolio,
      status,
      probabilityOfDefault,
    } = item;

    return {
      id,
      name: company?.name,
      isTemplateUploaded,
      fiscalYear: <DateTime>{closingDate}</DateTime>,
      rating: creditRating?.rating || 'n/a',
      createdAt: <DateTime>{createdAt}</DateTime>,
      updatedAt: <DateTime>{updatedAt}</DateTime>,
      deletedAt: <DateTime>{deletedAt}</DateTime>,
      editable,
      isPortfolio,
      status,
      probabilityOfDefault: `${displayNumber(probabilityOfDefault, userInfo.decimalPoint)}%`,
    };
  });
}

export function getCreditRatingsSheetData(data = [], userInfo) {
  return data.map((item) => {
    const { company, closingDate, creditRating, probabilityOfDefault, createdAt, status } = item;

    return {
      Company: company?.name,
      'Fiscal year': formatDateString(closingDate, userInfo.dateFormat),
      'Probability of default': `${probabilityOfDefault}%`,
      Rating: creditRating?.rating || 'n/a',
      Created: formatDateString(createdAt, userInfo.dateFormat),
      Status: status,
    };
  });
}

const columns = [
  { label: 'Company', sortBy: 'name', value: 'name' },
  { label: 'Fiscal Year', sortBy: 'fiscalYear', value: 'fiscalYear' },
  { label: 'Rating', sortType: 'array', sortArray: creditRatings, value: 'rating' },
  { label: 'Probability of default (1 yr.)', sortBy: 'probabilityOfDefault', value: 'probabilityOfDefault' },
  { label: 'Created', sortBy: 'createdAt', value: 'createdAt' },
  { label: 'Updated', sortBy: 'updatedAt', value: 'updatedAt' },
  { label: 'Deleted', sortBy: 'deletedAt', value: 'deletedAt' },
  {
    label: 'Status',
    sortBy: 'status',
    value: 'status',
    renderCustomCell: (item) => {
      return (
        <Text color={item.status === 'Final' ? 'deep-sapphire' : 'blaze-orange'} variant="m-spaced">
          {item.status}
        </Text>
      );
    },
  },
];

export function getCreditRatingsColumns(visibleColumns) {
  return getVisibleColumns({ columns, visibleColumns });
}
