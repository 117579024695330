import type { ClientType, CreateClientDataType } from 'types';
import { backend } from 'services';

import { apiPaths } from './routes';

export function getClient(clientId: string): Promise<ClientType> {
  return backend.get(`${apiPaths.client}/${clientId}`);
}

export function getClients(): Promise<ClientType[]> {
  return backend.get(apiPaths.client);
}

export function createClient(data: CreateClientDataType): Promise<ClientType> {
  return backend.post(apiPaths.client, data);
}

export function updateClient(data: { isLoanApproachCalculated: boolean }): Promise<ClientType> {
  return backend.patch(apiPaths.client, data);
}

export function deleteClient(id: number) {
  return backend.delete(`${apiPaths.client}/${id}`);
}
