import { DOT } from '~/enums';

export function capitalize(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export function separateThousands(string, decimalPoint = DOT) {
  if (string === 0) return '0';
  if (!string) return '';

  if (decimalPoint === DOT) {
    const result = string
      .toString()
      .split(/(?=(?:\d{3})+(?:\.|$))/g)
      .join(',')
      .replace(/\.,/, '.')
      .replace('-,', '-');

    const [wholeNumber, decimal] = result.split('.');
    if (decimal) {
      return [wholeNumber, decimal.replace(/,/g, '').substring(0, 3)].join('.');
    }
    return result;
  } else {
    const result = string
      .toString()
      .replace(/\./g, ',')
      .split(/(?=(?:\d{3})+(?:,|$))/g)
      .join('.')
      .replace(/,\./, ',')
      .replace('-.', '-');

    const [wholeNumber, decimal] = result.split(',');
    if (decimal) {
      return [wholeNumber, decimal.replace(/\./g, '').substring(0, 3)].join(',');
    }
    return result;
  }
}

export function displayNumber(string, decimalPoint = DOT, defaultValue = 'Unknown') {
  if (!string && string !== 0) {
    return defaultValue;
  }

  if (decimalPoint === DOT) {
    return new Intl.NumberFormat('en-EN', { maximumFractionDigits: 2 }).format(string);
  }

  return new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(string);
}

export function displayNumber2(
  string,
  { decimalPoint = DOT, defaultValue = 'Unknown', minDig, maxDig = 2, unit = '', currency = '' }
) {
  if (!string && string !== 0) {
    return defaultValue;
  }

  let formattedNumber;
  if (decimalPoint === DOT) {
    formattedNumber = new Intl.NumberFormat('en-EN', {
      minimumFractionDigits: minDig,
      maximumFractionDigits: maxDig,
    }).format(string);
  } else {
    formattedNumber = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: minDig,
      maximumFractionDigits: maxDig,
    }).format(string);
  }

  return `${currency} ${formattedNumber}${unit}`;
}

export function removeBlankSpaces(string) {
  return string ? string.replace(/\s+/g, '') : '';
}

export function removeNonDigits(string) {
  return string ? string.replace(/\D/g, '') : '';
}

export function removeNonDigitsNegative(string) {
  return string ? string.replace(/[^-0-9]/g, '') : '';
}

export function removeNonDigitsDecimal(string) {
  return string ? string.replace(/[^0-9.,]/g, '') : '';
}

export function removeNonDigitsDecimalNegative(string) {
  return string ? string.replace(/[^-0-9.,]/g, '') : '';
}

export function removeFileExtension(string) {
  return string ? string.match(/(.+?)(\.[^.]*$|$)/)[1] : '';
}
