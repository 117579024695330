import _ from 'lodash';
import React from 'react';
import { Column, useFlexLayout, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { Box, FlexLayout, Icon, Pagination, styles, Text, TextInput } from 'ui';
import { TableColumnType } from 'types';

const DEFAULT_CELL_WIDTH = 150;

interface GlobalFilterProps {
  globalFilter: string;
  setGlobalFilter: React.ChangeEvent<HTMLInputElement>;
}

function GlobalFilter({ globalFilter, setGlobalFilter }: GlobalFilterProps) {
  return (
    <TextInput placeholder="Search..." value={globalFilter} variant="searchable" width="l" onChange={setGlobalFilter} />
  );
}

type TableComponentPropsType = {
  rightTitleContent?: React.ReactNode;
  onItemClick?: React.MouseEventHandler<HTMLButtonElement>;
  updateSorting?: Function;

  sortedBy: string | null;
  serverSideSorting: boolean;
  customPageSize: number;
  customPageIndex: number;
  isPaginationHidden: boolean;
  noEntriesText: string;
  isSearchable: boolean;
  columns: readonly Column<object>[];
  data: readonly object[];
};

function TableComponent({
  columns,
  data,
  isSearchable,
  rightTitleContent,
  onItemClick,
  noEntriesText,
  customPageSize,
  customPageIndex,
  isPaginationHidden,
  serverSideSorting,
  updateSorting,
  sortedBy,
}: TableComponentPropsType) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    // pagination props
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    state: { pageIndex },
  }: any = useTable(
    { columns, data, initialState: { pageIndex: customPageIndex, pageSize: customPageSize } },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const updateSortingOnClick = (id: string, sortByToggleProps: { title: string }) => () => {
    if (sortByToggleProps.title == null) return;

    if (!updateSorting) {
      throw new Error('No update sorting function provided');
    }

    if (!sortedBy) return updateSorting(id); // Ascending sort
    if (id === sortedBy) return updateSorting(`${id}+desc`); // Descending sort
    if (id === sortedBy.split('+')[0] && sortedBy.includes('+desc')) return updateSorting(null); // Remove sort

    return updateSorting(id); // Change sort by attribute
  };

  const setupServerSideSorting = (serverSideSorting: boolean, column: any) => {
    if (!serverSideSorting) return;
    if (!sortedBy) return;

    const [sortId, sortDirection] = sortedBy.split('+');
    if (!sortId) return;

    if (sortId === column.getHeaderProps().key.split('header_')[1]) {
      column.canSort = true;
      column.isSorted = true;

      if (sortDirection === 'desc') column.isSortedDesc = true;
      else column.isSortedDesc = false;
    }
  };

  if (serverSideSorting && !updateSorting) {
    throw new Error(
      'Must supply updateSorting function if serverSideSorting is enabled. Can be either redux reducer or React state.'
    );
  }

  return (
    <FlexLayout flexDirection="column" space={4} sx={{ ...styles.typography.xScrollable }}>
      {(isSearchable || rightTitleContent) && (
        <FlexLayout alignItems="center" justifyContent="space-between">
          {isSearchable && <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />}
          {rightTitleContent && rightTitleContent}
        </FlexLayout>
      )}
      <Box {...getTableProps()} sx={{ minWidth: 'max-content !important' }}>
        {headerGroups.map((headerGroup: any) => (
          <FlexLayout {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => {
              const sortByToggleProps = column.getSortByToggleProps();
              if (sortByToggleProps.title == null) sortByToggleProps.style.cursor = 'auto'; // remove pointer cursor if not sortable (no sortBy provided in columns setup)

              return (
                <FlexLayout
                  {...column.getHeaderProps(sortByToggleProps)}
                  alignItems="center"
                  pl={onItemClick ? 4 : 0}
                  pr={4}
                  py={4}
                  onClick={
                    serverSideSorting ? updateSortingOnClick(column.id, sortByToggleProps) : sortByToggleProps.onClick
                  }
                >
                  <Text color="bali-hai" variant="s-spaced-medium-caps" sx={{ flexGrow: '1' }}>
                    {column.render('Header')}
                  </Text>
                  {setupServerSideSorting(serverSideSorting, column)}
                  {column.canSort && column.isSorted && (
                    <Icon color="deep-sapphire" icon={column.isSortedDesc ? 'chevronDown' : 'chevronUp'} size="xs" />
                  )}
                </FlexLayout>
              );
            })}
          </FlexLayout>
        ))}
        <Box {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <FlexLayout
                {...row.getRowProps()}
                sx={{
                  borderTop: 'border',
                  borderRadius: 's',
                  '&:hover': onItemClick ? { bg: 'light-gray' } : undefined,
                }}
              >
                {row.cells.map((cell: any) => {
                  const isActionsColumn = cell.column.Header === 'Actions';
                  return (
                    <FlexLayout
                      {...cell.getCellProps()}
                      alignItems="center"
                      justifyContent={cell.column.justifyContent}
                      pl={onItemClick ? 4 : 0}
                      pr={4}
                      py={isActionsColumn ? 0 : 4}
                      onClick={onItemClick && !isActionsColumn ? () => onItemClick(cell.row.original) : undefined}
                    >
                      <Text color="deep-sapphire" variant="m-spaced" sx={{ paddingRight: cell.column.paddingRight }}>
                        {cell.render('Cell')}
                      </Text>
                    </FlexLayout>
                  );
                })}
              </FlexLayout>
            );
          })}
          {page.length === 0 && (
            <FlexLayout alignItems="center" py={6} sx={{ borderTop: 'border' }}>
              <Text color="bali-hai" variant="m-spaced">
                {data.length === 0 ? noEntriesText : 'No items match your filter.'}
              </Text>
            </FlexLayout>
          )}
        </Box>
      </Box>
      {pageCount > 1 && !isPaginationHidden && (
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          pageCount={pageCount}
          forcePage={pageIndex}
          onPageChange={(pageClicked: any) => gotoPage(pageClicked.selected)}
        />
      )}
    </FlexLayout>
  );
}

interface TableProps {
  actionColumn?: Function | false;
  columns?: Array<TableColumnType<any>>;
  data?: Array<any>;
  isSearchable?: boolean;
  rightTitleContent?: React.ReactNode;
  onItemClick?: React.MouseEventHandler<HTMLButtonElement>;
  customPageSize?: number;
  customPageIndex?: number;
  isPaginationHidden?: boolean;
  serverSideSorting?: boolean;
  updateSorting?: Function;
  sortedBy?: string | null;
  noEntriesText?: string;
  isShowing?: boolean;
}

function Table(props: TableProps) {
  const {
    actionColumn,
    columns = [],
    data = [],
    isSearchable = false,
    rightTitleContent,
    onItemClick,
    noEntriesText = 'No entries.',
    customPageSize = 10,
    customPageIndex = 0,
    isPaginationHidden = false,
    serverSideSorting = false,
    updateSorting,
    sortedBy = null,
    isShowing = true,
  } = props;

  const tableColumns: any = columns.map((column) => {
    const {
      label,
      sortArray,
      sortBy,
      value,
      width,
      renderCustomCell,
      sortType = 'normal',
      justifyContent,
      sortingFn,
    } = column;

    return {
      accessor: value,
      Cell: ({ row, cell }: any) => (renderCustomCell ? renderCustomCell(row.original) : cell.value),
      disableSortBy: !sortBy,
      Header: label,
      width: width || DEFAULT_CELL_WIDTH,
      justifyContent: justifyContent || 'unset',
      paddingRight: justifyContent === 'flex-end' ? '40px' : '0px',
      height: 200,
      sortType:
        sortingFn ||
        (sortType === 'normal'
          ? (rowA: any, rowB: any, _id: any, desc: any) => {
              const valueA = _.get(rowA.original, sortBy);
              const valueB = _.get(rowB.original, sortBy);

              if (desc) {
                return valueA > valueB ? 1 : -1;
              } else {
                return valueA < valueB ? -1 : 1;
              }
            }
          : (rowA: any, rowB: any, _id: any, desc: any) => {
              const valueAIndex = sortArray!.indexOf(_.get(rowA.original, sortBy) as never);
              const valueBIndex = sortArray!.indexOf(_.get(rowB.original, sortBy) as never);

              if (desc) {
                return valueAIndex < valueBIndex ? 1 : -1;
              } else {
                return valueAIndex > valueBIndex ? -1 : 1;
              }
            }),
    };
  });

  if (actionColumn) {
    tableColumns.push({
      accessor: 'actions',
      justifyContent: 'center',
      Cell: ({ row }: any) => actionColumn(row.original),
      disableSortBy: true,
      Header: 'Actions',
      width: 80,
    });
  }

  const mappedColumns = React.useMemo(
    () => [...tableColumns],
    [tableColumns, props] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!isShowing) return null;

  return (
    <TableComponent
      columns={mappedColumns}
      data={data}
      isSearchable={isSearchable}
      rightTitleContent={rightTitleContent}
      onItemClick={onItemClick}
      noEntriesText={noEntriesText}
      customPageSize={customPageSize}
      customPageIndex={customPageIndex}
      isPaginationHidden={isPaginationHidden}
      serverSideSorting={serverSideSorting}
      updateSorting={updateSorting}
      sortedBy={sortedBy}
    />
  );
}

export default Table;
