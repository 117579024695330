import { isAfter } from 'date-fns';

import { reportStatusColors, lenderOrGuarantorTypeEnum } from 'enums';
import { creditRatings } from 'components/Shared/CreditRatingSingleSelect';
import { seniorities } from 'components/Shared/SenioritySingleSelect';
import { Text } from 'ui';
import { getCompanyRating, getCompanyRatingAdj } from 'utils/companies';
import { createTenor, formatDateString } from 'utils/dates';
import { getSheetData } from 'utils/excel';
import { getReportUnit } from 'utils/report';
import { capitalize, displayNumber2 } from 'utils/strings';
import { getVisibleColumns } from 'utils/tables';
import { UserInfoType } from 'types';

function getB2BLoanData(loan: any, userInfo: UserInfoType) {
  const {
    amount,
    ultimateBorrower,
    currency,
    editable,
    id,
    isPortfolio,
    issueDate,
    ultimateLender,
    maturityDate,
    paymentFrequency,
    rateType,
    report,
    seniority,
    status,
    type,
    isThirdParty,
    createdAt,
    updatedAt,
    deletedAt,
  } = loan;
  const statusPossibleExpire = isAfter(new Date(), new Date(maturityDate)) ? 'Expired' : status;
  const maxDig = rateType?.type === 'float' ? 0 : 2;
  const numberDisplayOptions = { decimalPoint: userInfo.decimalPoint, defaultValue: '-', maxDig, minDig: 0 };

  return {
    createdAt: formatDateString(createdAt, userInfo.dateFormat),
    updatedAt: formatDateString(updatedAt, userInfo.dateFormat),
    deletedAt: formatDateString(deletedAt, userInfo.dateFormat) || 'n/a',
    id,
    lenderName: ultimateLender?.name,
    borrowerName: ultimateBorrower?.name,
    currency: currency,
    amount: displayNumber2(amount, numberDisplayOptions),
    principalRepayment: 'At Maturity',
    rateType: capitalize(rateType?.type),
    loanType: type,
    dayCount: 'ACT/365',
    paymentFrequency,
    borrowerRatingStandalone: getCompanyRating(ultimateBorrower),
    borrowerRatingImplicitAdj: getCompanyRatingAdj(ultimateBorrower),
    tenor: displayNumber2(createTenor(issueDate, maturityDate).toFixed(2), numberDisplayOptions),
    isPortfolio,
    issueDate: formatDateString(issueDate, userInfo.dateFormat),
    maturityDate: formatDateString(maturityDate, userInfo.dateFormat),
    editable,
    status: statusPossibleExpire,
    seniority,
    finalInterestRate: displayNumber2(report.finalInterestRate, numberDisplayOptions) + getReportUnit(rateType, true),
    report,
    lowerBound: displayNumber2(report.lowerBound, numberDisplayOptions) + getReportUnit(rateType, true),
    midPoint: displayNumber2(report.midPoint, numberDisplayOptions) + getReportUnit(rateType, true),
    upperBound: displayNumber2(report.upperBound, numberDisplayOptions) + getReportUnit(rateType, true),
    lenderType: isThirdParty ? lenderOrGuarantorTypeEnum.THIRD_PARTY : lenderOrGuarantorTypeEnum.INTERCOMPANY,
  };
}

const columns = [
  { label: 'Primary Lender', sortBy: 'lenderName', value: 'lenderName', width: 250 },
  { label: 'Ultimate Borrower', sortBy: 'borrowerName', value: 'borrowerName', width: 250 },
  { label: 'Currency', sortBy: 'currency', value: 'currency' },
  { label: 'Amount', sortBy: 'amount', value: 'amount', justifyContent: 'flex-end' },
  { label: 'Principal repayment', sortBy: 'principalRepayment', value: 'principalRepayment' },
  { label: 'Rate type', sortBy: 'rateType', value: 'rateType' },
  { label: 'Loan type', sortBy: 'loanType', value: 'loanType' },
  { label: 'Day count', sortBy: 'dayCount', value: 'dayCount' },
  { label: 'Interest compounding frequency', sortBy: 'paymentFrequency', value: 'paymentFrequency' },
  {
    label: 'Ultimate Borrower rating standalone',
    sortArray: creditRatings,
    sortBy: 'borrowerRatingStandalone',
    sortType: 'array',
    value: 'borrowerRatingStandalone',
  },
  {
    label: 'Ultimate Borrower rating implicit adj.',
    sortArray: creditRatings,
    sortBy: 'borrowerRatingImplicitAdj',
    sortType: 'array',
    value: 'borrowerRatingImplicitAdj',
  },
  { label: 'Issue date', sortBy: 'issueDate', value: 'issueDate' },
  { label: 'Maturity date', sortBy: 'maturityDate', value: 'maturityDate' },
  { label: 'Tenor (yr.)', sortBy: 'tenor', value: 'tenor' },
  { label: 'Created', sortBy: 'createdAt', value: 'createdAt' },
  { label: 'Updated', sortBy: 'updatedAt', value: 'updatedAt' },
  { label: 'Deleted', sortBy: 'deletedAt', value: 'deletedAt' },
  { label: 'Seniority', sortArray: seniorities, sortBy: 'seniority', sortType: 'array', value: 'seniority' },
  {
    label: 'Status',
    sortBy: 'status',
    value: 'status',
    renderCustomCell: (item: any) => {
      return (
        <Text color={reportStatusColors[item.status as keyof typeof reportStatusColors]} variant="m-spaced">
          {item.status}
        </Text>
      );
    },
  },
  { label: 'Rate', sortBy: 'finalInterestRate', value: 'finalInterestRate', justifyContent: 'flex-end' },
  { label: 'Lower bound', sortBy: 'lowerBound', value: 'lowerBound', justifyContent: 'flex-end' },
  { label: 'Base', sortBy: 'midPoint', value: 'midPoint', justifyContent: 'flex-end' },
  { label: 'Upper bound', sortBy: 'upperBound', value: 'upperBound', justifyContent: 'flex-end' },
  { label: 'Lender Type', sortBy: 'lenderType', value: 'lenderType' },
];

export function getB2BLoansData(data: Array<any> = [], userInfo: UserInfoType) {
  return data.map((item) => getB2BLoanData(item, userInfo));
}

export function getB2BLoansSheetData(data = [], visibleColumns: Array<any>, userInfo: UserInfoType) {
  return getSheetData({ data, columns, visibleColumns, userInfo, getColumnData: getB2BLoanData });
}

export function getB2BLoansColumns(visibleColumns: Array<any>) {
  return getVisibleColumns({ columns, visibleColumns });
}
