import { add, addDays, differenceInCalendarDays, format, formatISO, intervalToDuration } from 'date-fns';
import moment from 'moment';

import { DATE_FNS_FORMATS, DATE_FNS_FORMATS_WITH_TIME } from '~/enums';

export const dateFnsFormatMapper = (format, withTime) => {
  if (withTime) {
    return DATE_FNS_FORMATS_WITH_TIME[format];
  }
  return DATE_FNS_FORMATS[format];
};

export function formatDateString(date, dateFormat, withTime) {
  if (!date) return;
  if (!dateFormat) throw new Error('Missing date format');

  const formatString = dateFnsFormatMapper(dateFormat, withTime);
  return format(new Date(date), formatString);
}

export function createTenor(date1, date2, unit = 'years') {
  return moment(date2, 'YYYY-MM-DD').diff(moment(date1, 'YYYY-MM-DD'), unit, true);
}

export function getUpdatedMaturityDate(maturityDate, issueDate, currentDate) {
  const diffDays = differenceInCalendarDays(new Date(maturityDate), new Date(issueDate));
  return addDays(currentDate, diffDays);
}

export function getEndDateFromTenor(issueDate, tenor) {
  const endDate = formatISO(add(new Date(issueDate), tenor), { representation: 'date' });
  return endDate;
}

export function getTenorFromEndDate(issueDate, endDate) {
  if (!issueDate || !endDate) return { years: null, months: null };

  const { years, months } = intervalToDuration({ start: new Date(issueDate), end: new Date(endDate) });

  return { years, months };
}
