import _ from 'lodash';
import React from 'react';

import { FlexLayout, Icon, Text } from '~/ui';

const currentYear = new Date().getFullYear();
const years = _.range(2009, currentYear + 30);

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const CustomDateInput = React.forwardRef(
  ({ onClick, placeholder, value, dateRangeValue, width, height = 'input-height' }, ref) => (
    <FlexLayout
      alignItems="center"
      px={4}
      ref={ref}
      sx={{ border: 'border', borderRadius: 'm', height, width }}
      onClick={onClick}
    >
      <Text color="deep-sapphire" variant="m-spaced">
        {dateRangeValue || value || placeholder}
      </Text>
    </FlexLayout>
  )
);

export function renderCustomHeader({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) {
  return (
    <FlexLayout alignItems="center" px={3} py={4} space={1}>
      <Icon
        color="bali-hai"
        disabled={prevMonthButtonDisabled}
        icon="arrowLeft"
        size="xs"
        sx={{ '&:hover': { color: !prevMonthButtonDisabled && 'deep-sapphire' } }}
        onClick={decreaseMonth}
      />
      <FlexLayout alignItems="center" justifyContent="center" flexGrow="1" space={1}>
        <select
          value={months[date.getMonth()]}
          onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </FlexLayout>

      <Icon
        color="bali-hai"
        disabled={nextMonthButtonDisabled}
        icon="arrowRight"
        size="xs"
        sx={{ '&:hover': { color: !nextMonthButtonDisabled && 'deep-sapphire' } }}
        onClick={increaseMonth}
      />
    </FlexLayout>
  );
}

export function setDate(date) {
  if (!date) return;

  if (typeof date === 'string') {
    // only ISO format includes a - and can be passed directly to Date
    if (date.includes('-')) {
      return new Date(date);
    }
  }

  return new Date(date);
}
